export const lang = {
    //LARA
    laraPersonalDataDesktop: 'Vou emitir a sua proposta. São apenas alguns segundos',
    laraIntroMessageHouse: 'Olá, eu sou a Lara a assistende digital da uthere! Vou procurar o melhor seguro para si',
    laraIntroMessageDesktop: 'Olá, vou procurar o melhor seguro para si em segundos. Que seguro procura?',
    laraBaseIntroMessageName: 'Olá {name}! Que seguro procura?',
    laraPersonalDataBuyMinutes: 'Vou emitir a sua proposta. São apenas alguns segundos',
    laraPersonalDataBuyTwo: 'Preciso de alguns dados pessoais seus',
    laraRateHelping: 'Com a sua opinião ajuda-nos a melhorar',
    laraPaymentDataDesktop: 'Qual a conta bancária que quer associar ao seu seguro?',
    laraPaymentDataDesktopSecondary: 'Parabéns por proteger a sua família',
    laraPaymentDataDesktopSecondaryTransfer: 'Pronto para começar a poupar?',
    laraIntroMessage: 'Vou procurar o melhor seguro para si em segundos',
    laraIntroMessageHome: 'Descubra o melhor seguro para si',
    laraIntroMessageLife: 'Olá, eu sou a Lara, a assistente digital da uthere. Que seguro de vida procura?',
    laraPaymentData: 'Em que conta bancária quer fazer o pagamento do seu seguro?',
    laraIntroMessageName: 'Olá {name}! Vou procurar o melhor preço para si',
    laraIntroMessageNameDesktop: 'Olá, eu sou a Lara, a assistente digital da uthere. Que seguro procura?',
    laraIntroMinutes: 'São apenas alguns minutos',
    laraHasDeedDate: 'Já tem data para a escritura?',
    laraTermsOfService: 'Já só falta que leia e aprove os nossos termos de serviço',
    laraProposal: 'Obrigada. Vou procurar a melhor proposta para si',
    laraProposalHome: 'Obrigada. Vou procurar a melhor proposta para si',
    laraProposalHomeDesktop: 'Obrigada. Vou procurar a melhor proposta para si',
    laraProposalShow: 'Pronto para ver a sua proposta?',
    laraProposalShow2: 'Se for como eu, vai querer um bom seguro e ajuda quando tiver um sinistro',
    laraProposalShow3: 'Se for como eu, vai querer um bom seguro e ajuda quando tiver um sinistro',
    laraWhatInsurance: 'Que seguro procura?',
    laraAlmostBirthday: 'Está quase a fazer anos 😀',
    laraHappyBirthday: 'Parabéns! 🎉🍾',
    laraHappyBirthdaySecondary: 'Parabéns!',
    laraAlmostBirthdaySecondary: 'Uau. Está quase a fazer anos 😀',
    laraChooseInsuranceCreditTransfer: 'Vamos a isso. Tenho conseguido uma poupança em 90% dos casos',
    laraChooseInsuranceCreditTransferLetter: 'Perfeito. Tenho conseguido uma poupança em 90% dos clientes que vivem {letterZone} e que nos têm pedido ajuda',
    laraChooseInsuranceCreditTransferLetterNoZone: 'Perfeito. Tenho conseguido uma poupança em 90% dos clientes que nos têm pedido ajuda',
    laraChooseInsuranceCreditBuy: 'Parabéns! Vou procurar o melhor seguro para si',
    laraChooseInsuranceHouse: 'Eu vou procurar o melhor seguro para si. Vamos  a isso?',
    laraChooseInsuranceLifeFamily: 'Vamos começar pelo seu nome',
    laraChooseInsuranceLifeAccident: 'Vou procurar o seguro que garante a sua maior protecção',
    laraContinueDesktop: 'Olá, eu sou a Lara, já tem uma uma simulação activa. Quer continuar a sua simulação ou começar uma nova?',
    laraContinue: 'Quer continuar a sua simulação ou começar uma nova?',
    laraInsuranceBank: 'Em que banco tem o seu crédito à habitação?',
    laraInsuranceBank2: 'Esta informação é importante para lhe poder apresentar uma proposta com as condições exigidas pelo seu banco',
    laraInsuranceBankBuy: 'Em que banco vai fazer o seu empréstimo?',
    laraInsuranceBankBuy2: 'Já escolheu o banco onde vai fazer o crédito à habitação?',
    laraName: 'Vamos começar pelo seu nome',
    laraBornDate0: 'Olá {name} 😀',
    laraHelloName: 'Olá {name} 😀',
    laraBornDate: 'Qual é a sua data de nascimento?',
    laraBornDateHouse : 'Por último, qual é a sua data de nascimento?',
    laraBornDateDesktop: 'Qual é a sua data de nascimento?',
    laraBornDateNoNameHelp: 'Eu vou ajudar. Vamos começar pela sua data de nascimento',
    laraBornDateNoName: 'Vamos começar pela sua data de nascimento',
    
    laraLifeCreditTransferBegin: 'Eu vou ajudar. E digo-lhe quanto pode poupar quando lhe apresentar a proposta',
    laraLifeCreditBuyBegin: 'Parabéns! Eu vou ajudar',
    laraCivilState: 'Qual é o seu estado civil?',
    laraChildren: 'Tem filhos?',
    laraOcupation: 'Qual é a sua profissão?',
    laraTripDesktopSecondary: 'Estamos quase!',
    laraOcupationActivities: 'Faz alguma destas actividades no seu dia-a-dia, mesmo que fora do seu trabalho?',
    laraInsureMonthlyValue: 'Por último, que valor mensal quer garantir à sua família?',
    laraInsureMonthlyValueAccident: 'Que rendimento mensal quer garantir em caso de acidente que cause invalidez?',
    laraTrip: 'Planeia fazer alguma viagem de mais de 30 dias, ou residir fora de Portugal nos próximos 12 meses?',
    laraTripDesktop: 'Planeia fazer alguma viagem de mais de 30 dias, ou residir fora de Portugal nos próximos 12 meses?',
    laraTripTwo: ' Planeiam fazer alguma viagem por mais de 30 dias ou residir fora de Portugal nos próximos 12 meses?',
    laraTripDontKnowInsuranceBank: 'Não tem problema. Podemos ajustar a proposta logo que decida em que banco vai fazer o empréstimo',
    laraResponseOcupation: 'Obrigada. Esta informação vai ajudar-me a ajustar a proposta à sua profissão',
    laraResponseOcupationOtherF: 'Esta informação vai ajudar-me a ajustar a proposta à profissão da sua mulher',
    laraResponseOcupationOtherM: 'Esta informação vai ajudar-me a ajustar a proposta à profissão do seu marido',
    laraResponseOcupationOther: 'Esta informação vai ajudar-me a ajustar a proposta à profissão da segunda pessoa',
    laraTripWhere: 'Para onde vai viajar?',
    laraTripWhereTwo: 'Para onde vai ou vão viajar?',
    laraQuestionaireName: '{name}, como avalia o seu estado de saúde?',
    laraQuestionaire: 'Estamos quase. Como avalia o seu estado de Saúde?',
    laraQuestionaireEmpty: '',
    laraQuestionairePersonalize: 'Vamos personalizar o seu seguro',
    laraQuestionairePersonalizeCredit: 'Vou personalizar o seu seguro para garantir que tem a melhor protecção possível',
    laraQuestionairePersonalizeFamily: 'Vou personalizar o seu seguro para garantir que a sua família fica protegida',
    laraQuestionaireOther: 'Como é que a segunda pessoa avalia o seu estado de saúde?',
    laraQuestionaireOtherF: 'Como é que a sua mulher avalia o seu estado de saúde?',
    laraQuestionaireOtherM: 'Como é que o seu marido avalia o seu estado de saúde?',
    laraExit: 'Fim',
    laraInsuranceInBank: 'Comprou o seguro actual no banco onde fez o seu empréstimo?',
    laraInsuranceInBankDesktop: 'Comprou o seguro actual no banco onde fez o seu empréstimo?',
    laraFantasticFamily: 'Parabéns! Que familia fantástica ❤️',
    laraInsuranceInBankResponse: 'Óptimo! Pode transferir o seu seguro sem preocupações. A sua prestação mensal bancária não vai alterar',
    laraKnowCurrentInsuranceCost: 'Sabe quanto paga por mês pelo seu seguro? Vai ajudar-me a calcular quanto pode poupar',
    laraKnowCurrentInsuranceCostDesktop: 'Sabe quanto paga por mês pelo seu seguro? Vai ajudar-me a calcular quanto pode poupar',
    laraInsuranceOwnership: 'Obrigada. O seguro está só em seu nome?',
    laraInsuranceOwnershipBuy: 'O seguro vai ficar apenas em seu nome? ',
    laraInsuranceOwnershipDontKnowInsuranceCost: 'Não tem problema. O seguro está em seu nome?',
    laraOtherNameWife: 'Como se chama a sua mulher?',
    laraOtherNameHusband: 'Como se chama o seu marido?',
    laraOtherNameOther: 'Como se chama a outra pessoa?',
    laraRemainingDebtValue: 'Qual o valor que ainda deve ao banco pelo seu empréstimo?',
    laraOtherBornDate2F: 'Vou também precisar de alguns dados da sua mulher',
    laraOtherBornDate2M: 'Vou também precisar de alguns dados do seu marido',
    laraOtherBornDate2: 'Vou também precisar de alguns dados da segunda pessoa',
    laraOtherBornDateF: 'Qual a data de nascimento da sua mulher?',
    laraOtherBornDateM: 'Qual a data de nascimento do seu marido?',
    laraOtherBornDate: 'Qual a data de nascimento da outra pessoa?',
    laraOcupationOther: 'Qual é a profissão da outra pessoa?',
    laraOcupationOtherF: 'Qual é a profissão da sua mulher?',
    laraOcupationOtherM: 'Qual é a profissão do seu marido?',
    laraOcupationActivitiesOther: 'A outra pessoa faz algumas destas actividades no seu dia-a-dia, mesmo fora do seu trabalho?',
    laraOcupationActivitiesOtherF: 'A sua mulher faz algumas destas actividades no seu dia-a-dia, mesmo fora do seu trabalho?',
    laraOcupationActivitiesOtherM: 'O seu marido faz algumas destas actividades no seu dia-a-dia, mesmo fora do seu trabalho?',
    laraTripWho: 'Vão viajar os dois?',
    laraTripWhereOtherF: 'Para onde vai viajar a {otherName}',
    laraTripWhereOther: 'Para onde vai viajar o {otherName}',
    laraTripWhereMain: { M: 'Para onde vai viajar o {name}?', F: 'Para onde vai viajar a {name}?', U: 'Para onde vai viajar o {name}?' },
    laraDebtValue0: 'Vou ajustar as coberturas ao que o {insuranceBank} pede',
    laraDebtValue0F: 'Vou ajustar as coberturas ao que a {insuranceBank} pede',
    laraDebtValueNoBank: 'Qual o valor do seu empréstimo?',
    laraDebtValue: 'Qual é o valor do crédito que vai pedir ao {insuranceBank}?',
    laraDebtValueF: 'Qual é o valor do crédito que vai pedir à {insuranceBank}?',
    laraGreatDecision: 'Óptima decisão 😀',
    laraPersonalDataFamily: 'Vou emitir a sua proposta. São apenas alguns segundos',
    laraNif: 'Qual é o seu NIF?',
    laraPersonalData: 'Vou precisar de alguns dados pessoais como NIF e número do cartão de cidadão',
    laraPersonalData: 'Vou precisar de alguns dados pessoais como NIF e número do cartão de cidadão',
    laraPersonalData2: 'Perfeito. Vou também precisar de alguns dados pessoais como NIF e número do cartão de cidadão',
    laraPersonalDataOther: 'Vou também precisar de alguns dados pessoais da segunda pessoa',
    laraPersonalDataOtherF: 'Vou também precisar de alguns dados pessoais da sua mulher',
    laraPersonalDataOtherM: 'Vou também precisar de alguns dados pessoais do seu marido',
    laraAddress: 'Qual é a sua morada?',
    laraHasBens: 'Tem bens que queira proteger?',
    laraHasBensDesktop : 'Tem bens que queira proteger? Por exemplo, sofás, computadores, ou bicicletas',
    laraHasBens2: 'Por exemplo, sofás, computadores, ou bicicletas',
    laraHouseBens: 'Procura um seguro para a sua casa ou apenas os seus bens?',
    laraHouseMaterialsConfirm: 'Vou considerar construção {recomendedMaterial}. É o mais habitual em casas construídas na altura em que a sua casa foi construída ',
    laraTransferTripDesktop: 'Por último, planeia fazer alguma viagem de mais de 30 dias, ou residir fora de Portugal nos próximos 12 meses?',
    laraPaymentTransfer: 'Obrigada {name}. Pronto para começar a poupar?',
    laraPaymentBuy: 'Qual a conta bancária que quer associar ao seu seguro?',
    laraPaymentData1: 'Em que conta bancária quer fazer o pagamento do seu seguro?',
    laraSignature: 'Obrigada. Já tenho tudo',
    laraSignature2: 'Agora é só assinar',
    laraSignatureBoth: 'Agora só preciso da sua assinatura e do {otherName}. Começamos pela sua',
    laraSignatureBothF: 'Agora só preciso da sua assinatura e da {otherName}. Começamos pela sua',
    laraSignatureOther: 'E agora a do {otherName}',
    laraSignatureOtherF: 'E agora a da {otherName}',
    laraEndSimulation: 'Já está! Parabéns {name}',
    laraIncludeBens: 'Vou incluir esta cobertura no seu seguro',
    laraIncludeBensCasa: 'Vou incluir estas coberturas no seu seguro',
    laraNifHelper : 'Preciso desta informação para encontrar o melhor preço possível para si',
    laraEndSimulation2: 'Já enviei a sua proposta para a {selectedInsurerName}',
    laraQuestionnaireCheckoutF: '{name}, vou precisar de alguns dados seus e da {otherName}. Vamos começar pelos seus dados de saúde',
    laraQuestionnaireCheckout: '{name}, vou precisar de alguns dados seus e do {otherName}. Vamos começar pelos seus dados de saúde',
    laraDeedDate: 'Em que data vai fazer a escritura da sua casa?',
    laraPersonalContractData : 'Vou emitir a sua proposta. São apenas alguns segundos',
    laraHouseAddressDesktop : 'Olá, eu sou a Lara, e vou procurar o melhor seguro para si. Qual é o código postal da sua casa?',
    laraHouseAddress : 'Olá, eu sou a Lara e vou procurar o melhor seguro para a sua casa. São apenas alguns segundos',
    laraHouseAddress2 : 'Vamos começar pela sua morada. Qual é o o código postal da sua casa?',
    laraHouseAddress3 : 'Perfeito! Vou precisar de saber a sua morada. Qual é o código postal da sua casa?',
    laraHouseAddress4 : 'Perfeito!',
    laraHouseAddress5 : 'Vou precisar de saber a sua morada. Qual é o o código postal da sua casa?',
    laraHouseAddressHelper : 'São apenas alguns segundos',
    laraHouseAddressHello : 'Olá, eu sou a Lara, vamos começar pela sua morada. Qual é o código postal da sua casa?',
    laraHouseType : 'Que tipo de casa é?',
    laraHouseFloor : 'É o último andar?',
    laraHouseUse : 'Obrigada. A casa é sua ou é arrendada?',
    laraHouseUse2 : 'A casa é sua ou é arrendada?',
    laraHouseUseDesktop : 'Perfeito. A casa é sua ou é arrendada?',
    laraHouseUseDesktop2 : 'Olá, eu sou a Lara, e vou procurar o melhor seguro para si. A casa é sua ou é arrendada?',
    laraHouseLive : 'É a sua residência habitual?',
    laraHouseLiveDesktop : 'E é a sua residência habitual?',
    laraHouseLive2 : 'Parabéns!. É para a sua residência habitual?',
    laraHouseLive3 : 'É a sua residência habitual?',
    laraHouseLiveOptions : 'Tem jardim, muros ou painéis solares?',
    laraHouseLiveOptions1 : 'Esta informação vai ajudar-me a procurar o seguro que melhor protege o seu jardim, os muros e os painéis solares',
    laraHouseAnimals : 'Tem animais de estimação (um cão ou um gato)?',
    laraHouseWork2 : 'O nosso designer também adora gatos 🐈',
    laraHouseWork1 : 'Eu também adoro cães 🐶',
    laraHouseBathrooms : 'Quantas casas de banho tem a sua casa?',
    laraHouseAnex0 : 'Obrigada. Isto vai ajudar-me a procurar as melhores coberturas para si',
    laraHouseAnexHouse : 'Tem garagem, arrecadação ou outro anexo?',
    laraHouseAnexHouse2 : 'Tem painéis solares, garagem ou outro anexo?',
    laraHouseWork : 'Utiliza a sua casa também como local de trabalho?',
    laraHouseAreaAnex : 'E qual é a área da sua arrecadação?',
    laraHouseAreaAnex1 : 'E qual é a área do seu sotão?',
    laraHouseAreaAnex2 : 'E qual é a área do seu anexo?',
    laraHouseAreaAnex3: 'E qual é a área da sua arrecadação e do sotão?',
    laraHouseAreaAnex4: 'E qual é a área da sua arrecadação e do anexo?',
    laraHouseAreaAnex5: 'E qual é a área do seu sotão e do anexo?',
    laraHouseAreaAnex6: 'E qual é a área da sua arrecadação, do sotão e do anexo?',
    laraHouseAreaHouse: 'Qual é a área da casa (m²)?',
    laraHouseYear: 'Qual é o ano de construção?',
    laraHouseYear2: 'Basta indicar o ano aproximado (por exemplo, 1970 ou 1980)',
    laraHouseRenovations: 'Foram feitas obras de renovação da canalização e parte eléctrica?',
    laraHouseRenovations2: 'Por renovação quero dizer renovação completa das canalizações e da parte eléctrica',
    laraHouseFinishings: 'Como descreveria os acabamento na sua casa?',
    laraHouseFinishings2: 'Esta informação ajuda-me a calcular a melhor cobertura para si. Pense em coisas como chão em madeira especial ou mármore',
    laraHouseMaterials: 'Qual é o material de construção da casa?',
    laraHouseMaterials2: 'Óptimo. Qual é o material de construção da casa?',
    laraHouseMaterials3: 'Vou simular como se não tivessem sido feitas obras de renovação',
    laraHouseEquipmentsDesktop: 'Obrigada. Tem algum destes equipamentos?',
    laraHouseEquipments: 'Tem algum destes equipamentos?',
    laraHouseActiveContract0: 'Já tenho praticamente tudo.',
    laraHouseHasFenomenos : 'O seu seguro actual tem a cobertura de fenómenos sísmicos?',
    laraHouseActiveContract2: 'Se escolher a nossa proposta eu trato de tudo. Informo a sua seguradora actual e o seu banco. Não tem que fazer nada',
    laraHouseActiveContract: 'Tem algum seguro activo para a sua casa?',
    laraHouseCredit: 'Tem algum empréstimo associado a esta casa?',
    laraHouseCreditDesktop: 'Já tenho praticamente tudo. Tem algum empréstimo associado a esta casa?',
    laraBornDateCredit: 'Se quiser eu depois ajudo-o a poupar no seguro de vida associado ao seu empréstimo',
    laraBornDateBuying: 'É uma óptima altura para comprar um seguro para a sua casa',
    laraHouseActiveContract3: 'Se escolher a nossa proposta eu trato de tudo, e informo a sua seguradora actual. Não tem que fazer nada',
    laraHasFenomenos2 : 'Vou incluir esta cobertura no seu seguro',
    laraHasFenomenos3 : 'Não tem problema. Caso queira, pode incluir esta cobertura na proposta que lhe apresentar',
    laraChooseInsuranceLife: 'Olá, que seguro de vida procura?',
    laraHouseEndSimulation: 'Parabéns {name}! Já enviei a sua proposta para a {selectedInsurerName}',
    laraExitSimulation: 'Obrigada. Ficamos felizes por ter gostado',
    laraExitSimulationBad: 'Obrigada. Já enviei o seu feedback à nossa equipa',
    laraConfirmBeggining: 'Vou precisar de alguns dados seus e em poucos segundos apresento-lhe o melhor preço. Pronto para começar?',
    laraHouseEndSimulationAnaliseTecnica: 'Já enviei a sua proposta para a Allianz',
    laraHouseEndSimulationAnaliseTecnica2: 'Volto ao seu contacto se precisar de mais alguma informação',
    laraAcceptLifeCreditBuy: 'Vou precisar de alguns dados seus e sobre o seu crédito. E em apenas alguns segundos apresento-lhe uma proposta. Pronto para começar?',
    laraCommentLifeFamilyChildren: 'Está em boas mãos. Temos ajudado muitas pessoas a escolher o seguro que melhor protege a sua família e os seus filhos',
    laraCommentLifeFamilyChild: 'Está em boas mãos. Temos ajudado muitas pessoas comprar o seguro que melhor protege a sua família e o seu filho',
    laraCommentLifeFamily: 'Está em boas mãos. Temos ajudado muitas pessoas comprar o seguro que melhor protege a sua família',
    laraCommentLifeTransfer: 'Está em boas mãos. Temos conseguido ajudar mais de 90% das pessoas a poupar no seguro de vida',
    laraCommentLifeBuy: 'Vou personalizar o seu seguro para garantir que tem a melhor protecção possível',
    laraActivitiesSimple: 'Estamos quase. Faz algum desporto?',
    laraActivities: 'Anda de mota? Faz algum desporto?',
    laraActivities2: 'Esta informação é importante para personalizar a sua proposta. Algumas seguradoras não cobrem alguns desportos',
    laraSports: 'Que desportos pratica?',
    laraActivitiesOther: 'A outra pessoa anda de mota? Faz algum desporto?',
    laraActivitiesOtherM: 'O seu marido anda de mota? Faz algum desporto?',
    laraActivitiesOtherF: 'A sua mulher anda de mota? Faz algum desporto?',
    laraFloorNumber: 'Qual é o andar?',
    laraHouseType: 'Que tipo de casa é?',
    laraHouseComercialUse : 'Como habitação ou com utilização comercial?',
    laraHouseComercialUse2 : 'Esta informação é importante, pois este seguro só cobre danos em habitações',
    laraEndContact : 'Obrigada. Vou enviar o seu contacto para o especialista que irá entrar em contacto consigo',
    laraHouseContact : 'Vou passar o seu contacto para um dos nossos especialistas',
    laraHouseContact2 : 'Qual é o seu email?',
    laraHouseContactDesktop : 'Vou passar o seu contacto para um dos nossos especialistas. Qual é o seu email?',
    laraHasBensArrendatario : 'Procura um seguro para a casa ou para os seus bens?',
    laraHasBensArrendatarioConfirmation : 'Infelizmente não o vou poder ajudar. Só consigo apresentar uma propostas para proteger os seus bens',
    laraHasBensArrendatarioConfirmationDesktop : 'Infelizmente não o vou poder ajudar. Só consigo apresentar uma propostas para proteger os seus bens. Quer um seguro só para os seus bens?',
    laraHasBensArrendatarioConfirmation2 : 'Quer ver um seguro só para os seus bens?',
    laraEndHouseArrendatario : 'Eu percebo. Até à próxima',
    laraHouseArrendatarioConfirmation : 'Este seguro vai proteger os seus bens',
    laraHouseArrendatarioConfirmation2 : 'A protecção das paredes (da casa) é responsabilidade do proprietário',
    laraEndSimulation : 'Já está!',
    //LABELS
    lblMotorcycle: 'Ando de mota',
    lblNif : "NIF",
    lblHouseBathrooms : 'Números casas de banho',
    lblHouseBens: 'Casa e bens',
    lblHouseBens2: 'Só bens',
    lblSports: 'Faço desporto',
    lblMotorcycleOther: 'Anda de mota',
    lblSportsOther: 'Faz desporto',
    lblContinueSimulation: 'Continuar simulação',
    lblContinue: 'Continuar',
    lblStartNewSimulation: 'Começar uma nova',
    lblDadosPessoais: 'Dados pessoais',
    lblHabitosVida: 'Hábitos de vida',
    lblDeclaracaoSaude: 'Declaraçãod de saúde',
    lblExit: 'Sair',
    lblAteJa: 'Até já',
    lblSelect: 'Seleccionar',
    lblSelectCountries: 'Lista de países',
    lblSelectSports: 'Lista desportos',
    lblSelectBank: 'Seleccione o banco',
    lblRemainingDebtValue: 'Valor em dívida',
    lblDebtValue: 'Valor do crédito',
    lblName: 'Nome',
    lblBorndDate: 'Data de nascimento',
    lblFamilyName: 'Nome de família',
    lblNo: 'Não',
    lblDontKnowYet: 'Não sei',
    lblYes: 'Sim',
    lblHas: 'Tem',
    lblDontHave : 'Não tem',
    lblConfirm: 'Confirmar',
    lblRadioChooseInsuranceBase1: 'Vida',
    lblRadioChooseInsuranceBase2: 'Casa',
    lblRadioChooseInsuranceBase3: 'Automóvel',
    lblRadioChooseInsurance1: '<span style="font-weight: 700">Poupar</span>&nbsp;no seguro Vida Crédito',
    lblRadioChooseInsurance2: 'Vida Crédito&nbsp;<span style="font-weight: 700">nova casa</span>',
    lblRadioChooseInsurance3: 'Proteger <span style="font-weight: 700">a minha família</span>',
    lblRadioChooseInsurance4: 'Seguro&nbsp;<span style="font-weight: 700">Casa</span>',
    lblCivilStateSolteiro: { M: 'Solteiro', F: 'Solteira', U: 'Solteiro' },
    lblCivilStateCasado: { M: 'Casado', F: 'Casada', U: 'Casado' },
    lblCivilStateUniao: { M: 'União de facto', F: 'União de facto', U: 'União de facto' },
    lblCivilStateDivorciado: { M: 'Divorciado', F: 'Divorciada', U: 'Divorciado' },
    lblCivilStateViuvo: { M: 'Viúvo', F: 'Viúvo', U: 'Viúvo' },
    lblChildren0: 'Não',
    lblChildren1: '1 filho',
    lblChildren2: '2 filhos',
    lblChildren3: '3 filhos',
    lblChildren4: 'Família numerosa',
    lblOcupationActivities1: 'Trabalho em altura',
    lblOcupationActivities2: 'Matérias perigosas',
    lblOcupationActivities3: 'Trabalho com máquinas',
    lblOcupationActivities4: 'Deslocações internacionais',
    lblOcupationActivitiesOther1: 'Trabalha em altura',
    lblOcupationActivitiesOther2: 'Matérias perigosas',
    lblOcupationActivitiesOther3: 'Trabalha com máquinas',
    lblOcupationActivitiesOther4: 'Deslocações internacionais',
    lblInsureMonthlyValue: 'Valor a garantir',
    lblDeedDate: 'Data escritura',
    lblInMyName: 'No meu nome',
    lblYear: "Ano",
    lblInMyNameAndWife: 'Meu e da minha mulher',
    lblInMyNameAndHusband: 'Meu e do meu marido',
    lblInMyNameAndOther: 'Meu e de outra pessoa',
    lblInMyNameBuy: 'Em meu nome',
    lblInMyNameAndWifeBuy: 'Em meu nome e da minha mulher',
    lblInMyNameAndHusbandBuy: 'Em meu nome e meu marido',
    lblInMyNameAndOtherBuy: 'Em meu nome e de outra pessoa',
    lblTravelTogether: 'Viajamos juntos',
    lblTravelNotTogether: 'Viajamos em separado',
    lblTravelOnlyMe: 'Só eu',
    lblTravelOnlyOther: 'Só o {otherName}',
    lblTravelOnlyOtherF: 'Só a {otherName}',
    lblSendingPropostal: 'A enviar a sua proposta para a {selectedInsurerName}',
    lblEndSimulation: 'Parabéns {name}<br/>Já enviei a sua proposta para a {selectedInsurerName}',
    lblHouseType1: 'Uma moradia',
    lblHouseType2: 'Um apartamento',
    lblHouseUse1: 'É minha',
    lblHouseUse2: 'Vou comprar',
    lblHouseUse3: 'Estou a arrendar',
    lblHouseLive1: 'Sim, vivo aqui',
    lblHouseLive2: 'Não, arrendei',
    lblHouseLive3: 'É a casa de férias',
    lblHouseLive4: 'Está desocupada',
    lblHouseLiveOptions1: 'Painéis solares',
    lblHouseLiveOptions2: 'Jardim',
    lblHouseLiveOptions3: 'Muros e portões',
    lblHouseAnimals1: 'Cão',
    lblHouseAnimals2: 'Gato',
    lblHouseWork1: 'Trabalho em casa',
    lblHouseWork2: 'Gosto de receber',
    lblHouseWork3: 'Airbnb',
    lblBackToHome: 'Voltar à página inicial',
    lblHouseAnex1: 'Garagem',
    lblHouseAnex2: 'Sotão',
    lblHouseAnex3: 'Arrecadação',
    lblHouseAnex4: 'Outro anexo',
    lblHouseAnex5: 'Painéis solares',
    lblArea: 'Área',
    lblHouseRenovationsYes: 'Sim',
    lblHouseRenovationsNo: 'Não',
    lblHouseRenovationsDontKnow: 'Não sei',
    lblHouseFinishings: 'Normais',
    lblHouseFinishings2: 'Qualidade elevada',
    lblHouseMaterials1: 'Tijolo e betão',
    lblHouseMaterials2: 'Outro',
    lblHouseMaterials3: 'Não sei',
    lblHouseEquipments1: 'Alarme',
    lblHouseEquipments2: 'Porta blindada',
    lblHouseEquipments3: 'Grades nas janelas',
    lblAgree: 'Concordo',
    lblUnderstand : 'Percebi',
    lblLetsGo: "Vamos a isso",
    lblHouseType2: 'Moradia',
    lblHouseType1: 'Apartamento',
    lblHouseComercialUse : 'Habitação',
    lblHouseComercialUse2 : 'É uma loja',
    lblHouseComercialUse3 : 'Tem outra utilização',
    lblHasBensArrendatario : 'Para a casa',
    lblHasBensArrendatario2 : 'Para os meus bens',
    lblHasBensArrendatario3 : 'Para a casa e para os meus bens',
    //USER
    userHouseBens: 'Para a minha casa e para os meus bens',
    userHouseBens2: 'Só para os meus bens',
    userContinue: '{continue}',
    userSports: '{whatSports}',
    userSportsOther: '{whatSportsOther}',
    userProposal: '<u>Escolho o seguro da {selectedInsurerName}</u>',
    userGenerateProposal: 'Quero ver a melhor proposta',
    userChooseInsurane: '{chooseInsurance}',
    userChooseLifeInsurane: '{chooseInsuranceLife}',
    userName: '{name} {familyNames}',
    userBornDate: '{bornDate}',
    userBornDateOther: '{bornDateOther}',
    userCivilState: '{civilState}',
    userChildrenMany: 'Somos uma família numerosa',
    userChildren: 'Tenho {children}',
    userNoChildren: '{children}',
    userOcupation: '{ocupation}',
    userOcupationOther: '{ocupationOther}',
    userOcupationActivities: '{ocupationActivities}',
    userOcupationActivitiesOther: '{ocupationActivitiesOther}',
    userInsureMonthlyValue: '{insureMonthlyValue}',
    userRemainingDebtValue: '{remainingDebtValue}',
    userTrip: '{trip}',
    otherName: '{otherName} {otherFamilyNames}',
    userTripWhere: '{tripWhere}',
    userChooseBaseInsuranceLife: 'Quero um seguro de vida',
    userChooseBaseInsuranceAuto : 'Quero um seguro para o meu carro',
    userChooseBaseInsuranceHouse: 'Quero um seguro para a minha casa',
    userTripWhereOther: '{tripWhereOther}',
    userQuestionnaireNoName: '<u>Confirmei os meus dados de saúde</u>',
    userQuestionnairePersonalize: '<u>Já está</u>',
    userQuestionnaire: '<u>Confirmei os meus dados de saúde</u>',
    userQuestionnaireOther: '<u>Dados de saúde {otherName}</u>',
    userPersonalDataNoName: '<u>Confirmei os meus dados pessoais</u>',
    userPersonalDataNoName: '<u>Confirmei os meus dados pessoais</u>',
    userPersonalData: '<u>Dados pessoais {name}</u>',
    userPersonalDataOther: '<u>Dados pessoais {otherName}</u>',
    userInsuranceInBankM: 'Sim, fiz o seguro e o empréstimo no {insuranceBank}',
    userInsuranceInBankF: 'Sim, fiz o seguro e o empréstimo na {insuranceBank}',
    userInsuranceInBankNo: 'Não',
    userInsuranceInBankBuyM: 'Sim, vou fazer o empréstimo no {insuranceBank}',
    userInsuranceInBankBuyF: 'Sim, vou fazer o empréstimo na {insuranceBank}',
    userInsuranceInBankBuyNo: 'Não',
    userKnowCurrentInsuranceCost: 'Pago por mês [toCurrency{currentInsuranceCost}]',
    userKnowCurrentInsuranceCostNo: 'Não',
    userInsuranceOwnership: '{insuranceOwnership}',
    userInsuranceBank: '{insuranceBank}',
    userMessageEndSimulation: '{rateDescription}',
    userTripWho: '{tripWho}',
    userHasDeedDateNo: 'Não',
    userHasDeedDate: 'Vou fazer a escritura no dia {deedDate}',
    userDebtValue: '{debtValue}',
    userFamilyInsurance: 'Quero proteger a minha família',
    userCreditSave: 'Quero poupar no meu seguro vida crédito habitação',
    userCreditBuy: 'Vou comprar uma casa e preciso de um seguro vida',
    userAddress: '{address}',
    userPaymentData: 'Associar à minha conta no {paymentDataBankName} ({paymentData})',
    userDeedDate: '{deedDate}',
    userTermsOfService: 'Aceito',
    userAreaOnly: 'A casa tem {houseArea}m²',
    userAreaAnex: '{houseAreaAnex}m²',
    userGreetingF: 'Olá Lara, sou a {name} {familyNames}',
    userGreetingM: 'Olá Lara, sou o {name} {familyNames}',
    userHouseAnex: '{houseAnex}',
    userHouseLive: '{houseLive}',
    userHouseRenovations: 'Foram feitas obras de renovação em {houseRenovationsYear}',
    userHouseRenovations2: '{houseRenovations}',
    userHouseMaterials: '{houseMaterials}',
    userHouseMaterials2: '{otherMaterials}',
    userRadioChooseInsurance1: 'Quero poupar no seguro vida crédito',
    userRadioChooseInsurance2: 'Vou comprar uma casa e preciso de um seguro de vida crédito',
    userRadioChooseInsurance3: 'Um seguro de vida para proteger a minha família',
    userActivities: '{activities}',
    userActivitiesOther: '{activitiesOther}',
    //Desktop
    desktopIntro: 'Você levará cerca de 5 minutos para responder a estas perguntas e ver as suas propostas ⏱️',
    desktopName: 'Exemplo com o nome',
}